'use client';
import { App, Layout } from 'antd';
import clsx from 'clsx';
import React, { memo, useEffect, useState } from 'react';
import Container from '@/components/atoms/Container';
import Header, { HeaderProps } from '@/components/atoms/Header';
import { useAuth } from '@/lib/hooks/useAuth';
import { useScroll } from 'framer-motion';
import { usePathname } from 'next/navigation';

const { Content } = Layout;

const OtherLayout = ({
  className,
  contentClassName,
  contentFullWidth,
  footerClassName,
  headers,
  children,
}: OtherLayoutProps) => {
  const { scrollY } = useScroll();
  const [positionY, setPositionY] = useState(0);
  const pathname = usePathname();

  useEffect(() => {
    const updatePositionY = (latest: number) => {
      setPositionY(latest);
    };
    const unsubY = scrollY.on('change', updatePositionY);
    return () => {
      unsubY();
    };
  }, [scrollY]);

  return (
    <App>
      <Layout
        className={clsx('layout overflow-x-hidden', {
          [`${className}`]: !!className,
        })}
      >
        <Header scrollY={positionY} {...headers} />

        <Container fullWidth={contentFullWidth} className="grow">
          <Content
            className={clsx({
              [`${contentClassName}`]: !!contentClassName,
              'mt-[99px] lg:mt-[4.5rem]': pathname !== '/',
              'lg:mt-[138.5px]': pathname === '/',
            })}
          >
            {children}
          </Content>
        </Container>
        <footer
          className={clsx('mx-7 my-10 xl:mx-25', {
            [`${footerClassName}`]: !!footerClassName,
          })}
        >
          <div className="flex flex-col items-center gap-4 xl:py-12 xl:flex-row">
            <span className="text-sm xs:text-base text-gray-400 xl:mr-auto">
              This site is protected by reCAPTCHA and the Google{' '}
              <span className="text-primary">Privacy Policy</span> and{' '}
              <span className="text-primary">Terms of Service</span> apply.
            </span>
            <div className="flex flex-col items-center gap-y-4 justify-end">
              <div className="flex flex-col gap-x-2 items-center xl:flex-row xl:mr-10 xl:gap-x-10 ">
                <span className="text-gray-400 hover:text-gray-500 cursor-pointer">
                  ข้อปฏิบัติและกฎหมาย
                </span>
                <span className="text-gray-400 hover:text-gray-500 cursor-pointer">
                  ข้อมูลส่วนบุคคล
                </span>
                <span className="text-gray-400 hover:text-gray-500 cursor-pointer">
                  นโยบายความเป็นส่วนตัว
                </span>
              </div>
            </div>
          </div>
        </footer>
      </Layout>
    </App>
  );
};

type OtherLayoutProps = {
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  footerClassName?: string;
  headers?: HeaderProps;
  contentFullWidth?: boolean;
};

export default memo(OtherLayout);
